import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ReactNode } from 'react';

// Create a custom QueryClient that tracks invalidation calls
class TrackingQueryClient extends QueryClient {
  private _hasInvalidated: boolean = false;

  get hasInvalidated(): boolean {
    return this._hasInvalidated;
  }

  set hasInvalidated(value: boolean) {
    this._hasInvalidated = value;
  }

  invalidateQueries(...args: Parameters<QueryClient['invalidateQueries']>) {
    this._hasInvalidated = true;
    return super.invalidateQueries(...args);
  }

  resetInvalidationFlag() {
    this._hasInvalidated = false;
  }
}

function QueryProvider({ children }: { children: ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = new TrackingQueryClient({
    mutationCache: new MutationCache({
      onSuccess: async (_data, _variables, _context, mutation) => {
        console.log('onSuccess calling provider');

        if (mutation.options.meta?.disableAutoInvalidate) {
          console.log('disableAutoInvalidate');
          return;
        }
        queryClient.resetInvalidationFlag();
        // Store the custom onSuccess to prevent it from being called multiple times
        const customOnSuccess = mutation.options.onSuccess;

        try {
          if (customOnSuccess) {
            console.log('custom on success');
            await customOnSuccess(_data, _variables, _context);
            mutation.options.onSuccess = undefined;
          }

          const excludedKeys = [
            'notifications',
            'showWebinars',
            'globalSettings',
            'enabledFeatures',
            'webinars',
            'blogsList',
          ];

          // If no custom invalidation occurred, do the default invalidation
          if (!queryClient.hasInvalidated) {
            await queryClient.invalidateQueries({
              predicate: (query) => {
                const firstKey = query.queryKey[0];
                return !excludedKeys.includes(firstKey as string);
              },
            });
          }
        } finally {
        }
      },
    }),
    queryCache: new QueryCache({
      onError: async (error) => {
        if (isAxiosError(error)) {
          const status = error?.response?.status;
          let message = 'An error occurred, please try again later.';
          const skipErrorHandling = [303];
          if (status && skipErrorHandling.includes(status)) {
            return;
          }

          switch (status) {
            case 400:
              message =
                (error?.response?.data?.detail ?? // For API project
                error?.response?.data?.message ??
                typeof error?.response?.data === 'string')
                  ? error?.response?.data
                  : 'An error occurred, please try again later.';
              break;

            case 403:
              message =
                "Permission denied! You don't have permission to perform this action";
              break;
            case 404: {
              const featureDisabledRegex =
                /The requested feature\(s\) (.*) are not available\./;
              const match = error?.response?.data.match(featureDisabledRegex);
              if (match) {
                return Promise.reject();
              }
              message = 'The requested resource was not found.';
              break;
            }
            case 500:
              message = 'An error occurred, please try again later.';
              break;
            default:
              message =
                error?.response?.data?.message ??
                'An error occurred, please try again later.';
              break;
          }

          enqueueSnackbar(message, { variant: 'error' });
        }
      },
    }),
    defaultOptions: {
      queries: {
        retry: (_failureCount, error: unknown): boolean => {
          if (isAxiosError(error)) {
            const status = error?.response?.status;

            if (status !== undefined) {
              return [500].includes(status);
            }
          }
          return false;
        },
        refetchOnWindowFocus: false,
      },
    },
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

export default QueryProvider;
