import { createRouter, RouterProvider } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';

import useFreshworksWidget from '@/components/hooks/useFreshdesk';
import LoadingCircular from '@/components/LoadingCircular';
import { Settings } from '@/contexts/Settings.types';
import { useFetchData } from '@/helpers/hooks';
import useAuth from '@/hooks/useAuth';
import { routeTree } from '@/routes';
import { useGlobalSettingsStore } from '@/store/useGlobalSettingsStore';
import { useShallow } from 'zustand/react/shallow';

/*
interface RouterErrorBoundaryProps {
  children?: React.ReactNode;
}

// This component is used to throw the error when there are no children
const ErrorThrower: React.FC<{ error: Error }> = ({ error }) => {
  throw error;
};
function RouterErrorBoundary({ children }: RouterErrorBoundaryProps) {
  const error = useRouteError();

  const errorMessage = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
      ? error.message
      : String(error);

  // Create an error object to pass to ErrorBoundary
  const errorObject = new Error(errorMessage);

  return (
    <ErrorBoundary>{children || <ErrorThrower error={errorObject} />}</ErrorBoundary>
  );
}
*/

// Create your route tree
const router = createRouter({ routeTree, context: { globalSettings: undefined! } });
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const App: React.FC = () => {
  const [isAuthenticated, isAuthenticateLoading] = useAuth();

  const { setIsAuthenticated, setGlobalSettings, isGAuthenticated } =
    useGlobalSettingsStore(
      useShallow((state) => ({
        setIsAuthenticated: state.setIsAuthenticated,
        setGlobalSettings: state.setGlobalSettings,
        isGAuthenticated: state.isAuthenticated,
      })),
    );

  const { data } = useFetchData<Settings>(['globalSettings'], '/user/getglobalsettings', {
    enabled: isGAuthenticated,
  });

  const [isAuthInitialized, setIsAuthInitialized] = useState(false);

  useEffect(() => {
    if (isAuthenticated !== isGAuthenticated) {
      setIsAuthenticated(isAuthenticated);
    }
  }, [isAuthenticated, isGAuthenticated, setIsAuthenticated]);

  useEffect(() => {
    if (data) {
      setGlobalSettings(data);
    }
    router.invalidate();
  }, [data]);

  useEffect(() => {
    if (isAuthenticated !== null && !isAuthenticateLoading) {
      setIsAuthInitialized(true);
    }
  }, [isAuthenticateLoading]);

  useFreshworksWidget(51000002765, isAuthenticated);

  if (!isAuthInitialized || isAuthenticateLoading) {
    return <LoadingCircular />;
  }

  return <RouterProvider router={router} context={{ globalSettings: data }} />;
};

export default App;
