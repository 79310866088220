import {
  createRootRouteWithContext,
  createRoute,
  redirect,
} from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { lazy } from 'react';

import { GenericErrorComponent } from '@/components/GenericErrorComponent';
import Root from '@/components/Root';
import { Settings, Role } from '@/contexts/Settings.types';
import { createRouteContent } from '@/helpers/createRouteContent';
import Strategies from '@/pages/strategies/Strategies';
import { getPages } from '@/components/navigation/navPages';

import {
  collaborateEditSearchSchema,
  engagementSearchSchema,
  knowledgebaseSearchSchema,
  ourCalendarSearchSchema,
  ourJourneySearchSchema,
  progressUpdateSearchSchema,
  rootSearchSchema,
  songlineSearchSchema,
  strategiesSearchSchema,
  vacancySearchSchema,
} from './types/itemFilter';

const VacancyPage = lazy(() => import('@/pages/vacancies/VacancyPage'));
const VacancyApplicationsList = lazy(
  () => import('@/pages/globalAdmin/vacancies/VacancyApplicationsList'),
);

const Hangfire = lazy(() => import('@/pages/admin/Hangfire'));
const Blogs = lazy(() => import('@/pages/blogs/Blogs'));
const BlogsLanding = lazy(() => import('@/pages/blogs/BlogsLanding'));
const ContentBuilder = lazy(() => import('@/pages/contentBuilder/ContentBuilder'));
const CaseStudiesLanding = lazy(() => import('@/pages/caseStudies/CaseStudiesLanding'));
const CaseStudyPage = lazy(() => import('@/pages/caseStudies/CaseStudyPage'));
const KnowledgeBaseLanding = lazy(
  () => import('./components/knowledgeBase/KnowledgeBaseLanding'),
);
const KnowledgeBasePage = lazy(
  () => import('./components/knowledgeBase/KnowledgeBasePage'),
);
const SubscriptionEdit = lazy(
  () => import('@/pages/globalAdmin/subscriptions/SubscriptionEdit'),
);
const NotAuthorized = lazy(() => import('@/pages/NotAuthorized'));
const Security = lazy(() => import('@/pages/Security'));

const NotFound = lazy(() => import('@/pages/NotFound'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'));
const TermsConditions = lazy(() => import('@/pages/TermsConditions'));

// Lazy load the components
const HomePage = lazy(() => import('@/pages/HomePage'));
const Login = lazy(() => import('@/pages/LoginPage'));
//const Strategies = lazy(() => import('@/pages/strategies/Strategies'));
const ProgressUpdateListTable = lazy(
  () => import('@/pages/progressUpdate/ProgressUpdateList'),
);
const AppLookups = lazy(() => import('@/pages/globalAdmin/appLookups/AppLookups'));
const AppModules = lazy(() => import('@/pages/globalAdmin/appLookups/AppModules'));
const BusinessUnits = lazy(() => import('@/pages/admin/BusinessUnits'));
const Profile = lazy(() => import('@/pages/user/Profile'));
const ProgressUpdate = lazy(() => import('@/pages/progressUpdate/ProgressUpdate'));
const OurJourney = lazy(() => import('@/pages/reports/ourJourney/OurJourney'));
const Roles = lazy(() => import('@/pages/globalAdmin/roles/Roles'));
const Users = lazy(() => import('@/pages/globalAdmin/users/Users'));
const Subscriptions = lazy(
  () => import('@/pages/globalAdmin/subscriptions/Subscriptions'),
);
// const ExcelImport = lazy(() => import('@/pages/admin/ExcelImport'));
const Scheduler = lazy(() => import('@/pages/admin/Scheduler'));
const System = lazy(() => import('@/pages/admin/System'));
const Songline = lazy(() => import('@/pages/reports/songline/Songline'));
const PhotoAlbum = lazy(() => import('@/pages/reports/PhotoAlbum'));
const OurCalendar = lazy(() => import('@/pages/reports/ourCalendar/OurCalendar'));
const OurUpdates = lazy(
  () => import('@/pages/progressUpdate/ProgressUpdate_UserActivities'),
);
const Vacancies = lazy(() => import('@/pages/vacancies/Vacancies'));

interface RouterContext {
  globalSettings: Settings;
}

// Helper function to check if the user has the required role
function checkRole(
  userRole: Role | undefined,
  allowedRoles: Role[] | undefined,
): boolean {
  if (!allowedRoles || allowedRoles.length === 0) return true;
  if (!userRole) return false;
  return allowedRoles.includes(userRole);
}

// Update the getAllowedRoles function to handle nested routes
function getAllowedRoles(path: string): Role[] | undefined {
  const pages = getPages([]);
  const pathParts = path.split('/').filter(Boolean);

  for (const page of pages) {
    if (typeof page === 'object') {
      const [key, value] = Object.entries(page)[0];

      if (pathParts[0].toLowerCase() === key.toLowerCase()) {
        if (pathParts.length === 1) {
          return value.roles;
        } else if (value.itemSets) {
          for (const itemSet of value.itemSets) {
            for (const item of itemSet.items) {
              if (
                typeof item === 'object' &&
                item.name?.replaceAll(' ', '_').toLowerCase() ===
                  pathParts[1].toLowerCase()
              ) {
                return item.roles;
              }
            }
          }
        }
      }
    }
  }
  return undefined;
}

function createBeforeLoad(path: string) {
  return ({ context }: { context: RouterContext }) => {
    const allowedRoles = getAllowedRoles(path);
    if (!context.globalSettings) return;
    if (!checkRole(context.globalSettings.role, allowedRoles)) {
      throw redirect({ to: '/not-authorized' });
    }
  };
}

const rootRoute = createRootRouteWithContext<RouterContext>()({
  component: Root,
});

const loginRoute = createRoute({
  path: '/',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Login, {
    title: 'Weavr',
    description:
      "Empower your organisation's reconciliation journey with weavr's innovative software and expert services. Track progress, manage strategies, and create meaningful impact for First Nations People. Discover our user-friendly tools and tailored support today.",
  }),
  errorComponent: GenericErrorComponent,
  validateSearch: zodValidator(rootSearchSchema),
});
const termsConditionRoute = createRoute({
  path: '/terms',
  getParentRoute: () => rootRoute,
  component: createRouteContent(TermsConditions, {
    title: 'Terms and conditions',
    description: 'Weavr | Terms and conditions',
  }),
  errorComponent: GenericErrorComponent,
});
const securityRoute = createRoute({
  path: '/security',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Security, {
    title: 'Security',
    description: 'Weavr | Security',
  }),
  errorComponent: GenericErrorComponent,
});
const privacyRoute = createRoute({
  path: '/privacy_policy',
  getParentRoute: () => rootRoute,
  component: createRouteContent(PrivacyPolicy, {
    title: 'Privacy policy',
    description: 'Weavr | Privacy policy',
  }),
  errorComponent: GenericErrorComponent,
});
const homeRoute = createRoute({
  path: '/home',
  getParentRoute: () => rootRoute,
  component: createRouteContent(HomePage, {
    title: 'Home',
    description: 'Weavr | Home Page',
  }),
  errorComponent: GenericErrorComponent,
  staticData: { excludeSitemap: true },
  beforeLoad: createBeforeLoad('/home'),
});
const strategiesRoute = createRoute({
  path: '/strategies',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Strategies, {
    title: 'Strategies',
    description: 'Weavr | Strategies',
  }),
  errorComponent: GenericErrorComponent,
  validateSearch: zodValidator(strategiesSearchSchema),
  beforeLoad: createBeforeLoad('/strategies'),
});

const collaborateRoute = createRoute({
  path: '/collaborate',
  getParentRoute: () => rootRoute,
  component: createRouteContent(ProgressUpdateListTable, {
    title: 'Collaborate',
    description: 'Weavr | Collaborate',
  }),
  errorComponent: GenericErrorComponent,
  validateSearch: zodValidator(progressUpdateSearchSchema),
  beforeLoad: createBeforeLoad('/collaborate'),
});
const ourJourneyRoute = createRoute({
  path: '/report/our_journey',
  getParentRoute: () => rootRoute,
  component: createRouteContent(OurJourney, {
    title: 'Our Journey',
    description: 'Weaver | Our Journey Report',
  }),
  errorComponent: GenericErrorComponent,
  validateSearch: zodValidator(ourJourneySearchSchema),
  staticData: { excludeSitemap: true },
  beforeLoad: createBeforeLoad('/report/our_journey'),
});
const ourCalendarRoute = createRoute({
  path: '/report/our_calendar',
  getParentRoute: () => rootRoute,
  component: createRouteContent(OurCalendar, {
    title: 'Our Calendar',
    description: 'Weaver | Our Calendar Report',
  }),
  errorComponent: GenericErrorComponent,
  validateSearch: zodValidator(ourCalendarSearchSchema),
  staticData: { excludeSitemap: true },
  beforeLoad: createBeforeLoad('/report/our_calendar'),
});
const ourUpdateRoute = createRoute({
  path: '/report/our_updates',
  getParentRoute: () => rootRoute,
  component: createRouteContent(OurUpdates, {
    title: 'Our Updates',
    description: 'Weaver | Our Updates Report',
  }),
  errorComponent: GenericErrorComponent,
  staticData: { excludeSitemap: true },
  validateSearch: zodValidator(engagementSearchSchema),
  beforeLoad: createBeforeLoad('/report/our_updates'),
});
const ourSonglineRoute = createRoute({
  path: '/report/our_songline',
  getParentRoute: () => rootRoute,
  validateSearch: zodValidator(songlineSearchSchema),
  component: createRouteContent(Songline, {
    title: 'Songline',
    description: 'Weaver | Our Songline Report',
  }),
  errorComponent: GenericErrorComponent,
  staticData: { excludeSitemap: true },
  beforeLoad: createBeforeLoad('/report/our_songline'),
});
const photoAlbumRoute = createRoute({
  path: '/report/photo_album',
  getParentRoute: () => rootRoute,
  validateSearch: zodValidator(songlineSearchSchema),
  component: createRouteContent(PhotoAlbum, {
    title: 'Photo Album',
    description: 'Weaver | Photo Album',
  }),
  errorComponent: GenericErrorComponent,
  staticData: { excludeSitemap: true },
  beforeLoad: createBeforeLoad('/report/our_songline'),
});
const collaborateEditRoute = createRoute({
  path: '/collaborate/edit',
  getParentRoute: () => rootRoute,
  component: createRouteContent(ProgressUpdate, {
    title: 'Progress update Edit',
    description: 'Weaver | Progress update',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/collaborate/edit'),
  validateSearch: zodValidator(collaborateEditSearchSchema),
});
const hangfireRoute = createRoute({
  path: '/admin/hangfire',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Hangfire, {
    title: 'Hangfire Dashboard',
    description: 'Hangfire Dashboard',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/admin/hangfire'),
});
const businessUnitRoute = createRoute({
  path: '/admin/business_units',
  getParentRoute: () => rootRoute,
  component: createRouteContent(BusinessUnits, {
    title: 'Admin - Weavr',
    description: 'Weavr | Business units',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/admin/business_units'),
});
const userListRoute = createRoute({
  path: '/admin/users',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Users, {
    title: 'Admin - Weavr',
    description: 'Weavr | Admin page',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/admin/users'),
});
const subscriptionRoute = createRoute({
  path: '/admin/subscription',
  getParentRoute: () => rootRoute,
  component: createRouteContent(SubscriptionEdit, {
    title: 'Subscription',
    description: 'Weavr | Subscription',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/admin/subscription'),
});
const lookupRoute = createRoute({
  path: '/global_admin/app_lookups',
  getParentRoute: () => rootRoute,
  component: createRouteContent(AppLookups, {
    title: 'App Lookups',
    description: 'Weavr | App lookup',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/app_lookups'),
});
const moduleRoute = createRoute({
  path: '/global_admin/app_modules',
  getParentRoute: () => rootRoute,
  component: createRouteContent(AppModules, {
    title: 'App Modules',
    description: 'Weavr | App modules',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/app_modules'),
});
const adminUserListRoute = createRoute({
  path: '/global_admin/users',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Users, {
    title: 'Users',
    description: 'Weavr | global admin - users',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/users'),
});
const roleRoute = createRoute({
  path: '/global_admin/roles',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Roles, {
    title: 'Roles',
    description: 'Weavr | global admin - roles',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/roles'),
});
const subscriptionListRoute = createRoute({
  path: '/global_admin/subscriptions',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Subscriptions, {
    title: 'Subscriptions',
    description: 'Weavr | global admin - subscriptions',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/subscriptions'),
});
const globalSubscriptionRoute = createRoute({
  path: '/global_admin/subscriptions/$id',
  getParentRoute: () => rootRoute,
  component: createRouteContent(SubscriptionEdit, {
    title: 'Subscription',
    description: 'Weavr | global admin - subscription',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/subscriptions/$id'),
});
const schedulerRoute = createRoute({
  path: '/global_admin/scheduler',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Scheduler, {
    title: 'Scheduler',
    description: 'Weavr | global admin - scheduler',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/scheduler'),
});
const systemRoute = createRoute({
  path: '/global_admin/system',
  getParentRoute: () => rootRoute,
  component: createRouteContent(System, {
    title: 'System Info',
    description: 'Weavr | System info',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/system'),
});
const profileRoute = createRoute({
  path: '/profile',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Profile, {
    title: 'Profile',
    description: 'Weavr | Profile',
  }),
  errorComponent: GenericErrorComponent,
  staticData: { excludeSitemap: true },
  beforeLoad: createBeforeLoad('/profile'),
});
const contentBuilderRoute = createRoute({
  path: '/global_admin/content_builder',
  getParentRoute: () => rootRoute,
  component: createRouteContent(ContentBuilder, {
    title: 'Content Builder',
    description: 'Weavr | Content builder',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/content_builder'),
});
const careerRoute = createRoute({
  path: '/careers',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Vacancies, {
    title: 'Careers',
    description: 'Weavr | Careers',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/careers'),
});
const careerContentRoute = createRoute({
  path: '/careers/$name',
  getParentRoute: () => rootRoute,
  component: createRouteContent(VacancyPage, {
    title: 'Careers',
    description: 'Weavr | Careers',
  }),
  errorComponent: GenericErrorComponent,
  validateSearch: zodValidator(vacancySearchSchema),
  beforeLoad: createBeforeLoad('/careers/$name'),
});
const vacancyRoute = createRoute({
  path: '/global_admin/candidate_applications',
  getParentRoute: () => rootRoute,
  component: createRouteContent(VacancyApplicationsList, {
    title: 'Application Management',
    description: 'Weavr | Manage Candidate Applications',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/global_admin/candidate_applications'),
});
const blogRoute = createRoute({
  path: '/blogs',
  getParentRoute: () => rootRoute,
  component: createRouteContent(BlogsLanding, {
    title: 'Blog',
    description: 'Weavr | Blogs',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/blogs'),
});
const blogContentRoute = createRoute({
  path: '/blogs/$name',
  getParentRoute: () => rootRoute,
  component: createRouteContent(Blogs, {
    title: 'Blog',
    description: 'Weavr | Blogs',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/blogs/:name'),
});

const caseStudiesRoute = createRoute({
  path: '/casestudies',
  getParentRoute: () => rootRoute,
  component: createRouteContent(CaseStudiesLanding, {
    title: 'Case Studies',
    description: 'Weavr | Case Studies',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/casestudies'),
});
const caseStudyContentRoute = createRoute({
  path: '/casestudies/$name',
  getParentRoute: () => rootRoute,
  component: createRouteContent(CaseStudyPage, {
    title: 'Case Studies',
    description: 'Weavr | Case Studies',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/casestudies/:name'),
});

const knowledgeBaseList = createRoute({
  path: '/knowledgebase',
  getParentRoute: () => rootRoute,
  component: createRouteContent(KnowledgeBaseLanding, {
    title: 'Knowledge Base',
    description: 'Weavr | Knowledge Base',
  }),
  errorComponent: GenericErrorComponent,
  validateSearch: zodValidator(knowledgebaseSearchSchema),
  beforeLoad: createBeforeLoad('/knowledgebase'),
});
const knowledgeBase = createRoute({
  path: '/knowledgebase/$title',
  getParentRoute: () => rootRoute,
  component: createRouteContent(KnowledgeBasePage, {
    title: 'Knowledge Base',
    description: 'Weavr | Blogs',
  }),
  errorComponent: GenericErrorComponent,
  beforeLoad: createBeforeLoad('/knowledgebase/$title'),
});
const notAuthRoute = createRoute({
  path: '/not-authorized',
  getParentRoute: () => rootRoute,
  component: createRouteContent(NotAuthorized, {
    title: 'Not Authorized',
    description: 'Weavr | Not Authorized to view this page',
  }),
  errorComponent: GenericErrorComponent,
  staticData: { excludeSitemap: true },
});
const notFoundRoute = createRoute({
  path: '*',
  getParentRoute: () => rootRoute,
  component: createRouteContent(NotFound, {
    title: '404 Not Found',
    description: 'Weavr | Page not found',
  }),
  errorComponent: GenericErrorComponent,
  staticData: { excludeSitemap: true },
});

export const routeTree = rootRoute.addChildren([
  loginRoute,
  termsConditionRoute,
  securityRoute,
  privacyRoute,
  homeRoute,
  strategiesRoute,
  collaborateRoute,
  collaborateEditRoute,
  ourJourneyRoute,
  ourCalendarRoute,
  ourUpdateRoute,
  ourSonglineRoute,
  photoAlbumRoute,
  hangfireRoute,
  businessUnitRoute,
  userListRoute,
  adminUserListRoute,
  subscriptionRoute,
  lookupRoute,
  moduleRoute,
  roleRoute,
  subscriptionListRoute,
  globalSubscriptionRoute,
  schedulerRoute,
  systemRoute,
  profileRoute,
  contentBuilderRoute,
  careerRoute,
  careerContentRoute,
  vacancyRoute,
  blogRoute,
  blogContentRoute,
  caseStudiesRoute,
  caseStudyContentRoute,
  knowledgeBaseList,
  knowledgeBase,
  notAuthRoute,
  notFoundRoute,
]);
