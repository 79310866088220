import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { Box, Chip, Collapse, IconButton, Tooltip as MuiTooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import React, { useMemo, useState } from 'react';

import HeaderDiv from '@/pages/strategies/HeaderDiv';
import PlusMinusIcon from '@/pages/strategies/Icons/PlusMinusIcon';
import { Store, useStrategyStore } from '@/pages/strategies/store/useStrategyStore';

type Props = {
  selectedItemIds?: string[];
  handleSelectAll?: () => void;
  parentItemId?: string;
  handleAddClick: () => void;
  isExpandAll: boolean;
  handleExpandAll: () => void;
  backgroundColor: string;
  selectAll?: boolean;
  name: 'Actions' | 'Deliverables' | 'Contributions';
  title?: string;
  handleAltOptionClicked?: () => void;
  allowLinkDeliverable?: boolean;
  allowAddContribution?: boolean;
  count: number;
  disabled: boolean;
};

const StrategyPanelHeader = ({
  selectedItemIds,
  handleSelectAll,
  parentItemId,
  handleAddClick,
  isExpandAll,
  handleExpandAll,
  backgroundColor,
  selectAll,
  name,
  title,
  handleAltOptionClicked,
  allowLinkDeliverable,
  allowAddContribution = true,
  count,
  disabled,
}: Props) => {
  const enableLinkDeliverable = name == 'Deliverables' && allowLinkDeliverable;

  const [titleHovering, setTitleHovering] = useState<boolean>(false);

  const setStrategyPanelSize = useStrategyStore(
    (state: Store) => state.setStrategyPanelSize,
  );

  const divId = useMemo(() => {
    switch (name) {
      case 'Actions':
        return 'div1';
      case 'Deliverables':
        return 'div2';
      case 'Contributions':
        return 'div3';
      default:
        return 'div1';
    }
  }, [name]);

  return (
    <HeaderDiv style={{ backgroundColor: backgroundColor, marginBottom: 10 }}>
      <div
        id={`${name}-header`}
        style={{
          color: 'white',
          fontSize: 20,
          fontWeight: 700,
          flexBasis: '90%',
          paddingBlock: '0.4rem',
        }}
      >
        <div
          style={{ display: 'flex' }}
          onMouseEnter={() => setTitleHovering(true)}
          onMouseLeave={() => setTitleHovering(false)}
        >
          <Collapse
            orientation="horizontal"
            in={titleHovering || (selectedItemIds && selectedItemIds?.length > 0)}
          >
            <Box
              sx={{
                display: 'flex',
                width: 150,
              }}
            >
              <Tooltip title={'Select All'}>
                <Checkbox
                  sx={{
                    color: '#f0f0f0',
                    '&.Mui-checked': {
                      color: '#ffffff',
                    },
                  }}
                  checked={selectAll}
                  onChange={handleSelectAll}
                  disabled={disabled}
                />
              </Tooltip>
              <PlusMinusIcon
                className="ml-2"
                setSize={(num) => setStrategyPanelSize(num, divId)}
              />
            </Box>
          </Collapse>
          <div
            className="ml-2 flex items-center"
            id={`${name}-header`}
            style={{
              color: 'white',
              fontSize: 20,
              fontWeight: 700,
              flexBasis: '75%',
            }}
          >
            {`${title ?? name}`}
            <Chip className="ml-2" label={count} size="small" color="primary" />
          </div>
        </div>
      </div>
      {!!parentItemId && enableLinkDeliverable && (
        <span>
          <MuiTooltip title="Link deliverable">
            <span>
              <IconButton onClick={handleAltOptionClicked} disabled={disabled}>
                <AddLinkIcon style={{ color: 'white' }}></AddLinkIcon>
              </IconButton>
            </span>
          </MuiTooltip>
        </span>
      )}
      {!!parentItemId && allowAddContribution && (
        <span>
          <MuiTooltip title={'Add ' + name.slice(0, -1)}>
            <span>
              <IconButton
                onClick={handleAddClick}
                id={`btn-${name.slice(0, -1).toLowerCase()}-add`}
                disabled={disabled}
              >
                <AddCircleIcon style={{ color: 'white' }}></AddCircleIcon>
              </IconButton>
            </span>
          </MuiTooltip>
        </span>
      )}
      <MuiTooltip title={isExpandAll ? 'Collapse All' : 'Expand All'}>
        <IconButton onClick={handleExpandAll}>
          {isExpandAll ? (
            <UnfoldLess style={{ color: 'white' }}></UnfoldLess>
          ) : (
            <UnfoldMore style={{ color: 'white' }}></UnfoldMore>
          )}
        </IconButton>
      </MuiTooltip>
    </HeaderDiv>
  );
};
export default StrategyPanelHeader;
