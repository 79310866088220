import { useEffect } from 'react';

declare global {
  interface Window {
    fwSettings: { widget_id: number };
    FreshworksWidget: any;
  }
}

function useFreshworksWidget(widgetId: number, shouldLoad: boolean): void {
  useEffect(() => {
    if (shouldLoad) {
      window.fwSettings = {
        widget_id: widgetId,
      };
      if (typeof window.FreshworksWidget !== 'function') {
        const n: { q: any[] } = function (...args: any[]) {
          n.q.push(...args);
        };
        n.q = [];
        window.FreshworksWidget = n;
        const script = document.createElement('script');
        script.src = `https://aus-widget.freshworks.com/widgets/${widgetId}.js`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
    }
  }, [widgetId, shouldLoad]);
}

export default useFreshworksWidget;
